<template>
	<div class="main">

		<!-- 周边活动 -->
		<div class="around_active">
			<!-- ← -->
			<div class="aleft bg1">
				<div class="title">
					<img src="../assets/index/active1.png" alt="">
					<h2>周边活动</h2>
				</div>
				<div class="list">
					<h3>热门目的地</h3>
					<ul>
						<li>
							<a href="#" @click.prevent="search1(1,'')" :class="obj1.destination=='' ? 'active' : ''">全部</a>
						</li>
						<li v-for="(item,i) in leftlist1.destination" :key="i">
							<a href="#" @click.prevent="search1(1,item)"
							:class="obj1.destination==item ? 'active' : ''">· {{item}}</a>
						</li>

					</ul>
				</div>
				<div class="list">
					<h3>热门主题</h3>
					<ul>
						<li>
							<a href="#" @click.prevent="search1(2,'')" :class="obj1.features=='' ? 'active' : ''">全部</a>
						</li>
						<li v-for="(item,i) in leftlist1.features" :key="i">
							<a href="#" @click.prevent="search1(2,item)" :class="obj1.features==item ? 'active' : ''">· {{item}}</a>
						</li>
					</ul>
				</div>
				<div class="list">
					<h3>游玩天数</h3>
					<ul>
						<li>
							<a href="#" @click.prevent="search1(3,'')" :class="obj1.t_days=='' ? 'active' : ''">全部</a>
						</li>
						<li  v-for="i in 3" :key="i">
							<a href="#"  @click.prevent="search1(3,i)" :class="obj1.t_days==i ? 'active' : ''">· {{i}}天</a>
						</li>
						<li >
							<a href="#"  @click.prevent="search1(3,'3d')" :class="obj1.t_days=='3d' ? 'active' : ''">· 3天以上</a>
						</li>

					</ul>
				</div>

				<img src="../assets/index/img1.png" alt="上海周边活动" class="img">
			</div>
			<!-- → -->
			<div class="aright">
				<div class="title flex-between">
					<p>
						<span class="active">精选</span>
						<span v-for="(item,i) in leftlist1.provinces" :key="i" @click="search1(4,item)" style="cursor:pointer">
							{{item}}
						</span>
					</p>
					<img src="../assets/index/more.png" alt="" @click="toactivelist('/peripheralActivities')">
				</div>
				<div class="activelist">
					<div class="item"  v-for="(item,i) in goodsIndexList1" :key="i" @click="todetails(item.id)">
						<div class="img">
							<img :src="item.goods_pic" alt="" class="image">
							<div class="day">
								<p class="d1"><span>{{item.t_days}}</span> 日</p>
								<p class="d2">行程天数</p>
							</div>
							<div class="adress">{{item.origin_name}}出发</div>
							<div class="join">{{item.porder_count}}人已参加</div>
						</div>
						<div class="text">
							<h3>{{item.goods_name | wuellipsis(40)}}</h3>
							<div class="price-item" v-if="item.price_type === 2">
								<span class="label">预售定金</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<div class="price-item" v-else-if="item.price_type == 1">
								<span class="label">限时特价</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<p v-else><span>¥{{item.price | priceFilter}}</span> 起</p>
						</div>
						<div class="time">
							<div class="date-label"></div>
							<div v-for="(tuan,j) in item.goods_price.slice(0,3)" :key="j" class="tt">
								<p class="item-date">{{tuan.buy_date}}</p>
								<p v-if="tuan.is_team==1" class="item-status orange">已成团</p>
								<p v-else-if="tuan.remain_number === 0 && tuan.remain_alternate_number === 0" class="item-status orange">已满团</p>
								<p class="item-status" v-else>可报名</p>
								<!-- <p v-if="tuan.remain_number > 0" class="item-status orange">余位{{tuan.remain_number}}人</p>
								<p v-if="tuan.is_alternate === 1 && tuan.remain_number === 0 && tuan.remain_alternate_number > 0" class="item-status orange">候补{{tuan.remain_alternate_number}}人</p> -->
							</div>
						</div>
					</div>
					<div  class="nothing" v-if="goodsIndexList1.length==0">
						<img src="../assets/nothing.png" alt=""/>
					</div>

				</div>
			</div>
		</div>
		<!-- 广告1 -->
		<div class="twoimg flex-between">

			<img :src="item.attachment"  alt="" v-for="(item,i) in recommendList1" :key="i" @click="tourl(item)">
		</div>
		<div class="line flex-between">
			<div class="y"></div>
			<div class="l"></div>
			<div class="y"></div>
		</div>
		<!-- 国内长途 -->
		<div class="around_active">
			<!-- ← -->
			<div class="aleft bg2">
				<div class="title">
					<img src="../assets/index/active2.png" alt="">
					<h2>国内长途</h2>
				</div>
				<div class="list">
					<h3>热门目的地</h3>
					<ul>
						<li>
							<a href="#" @click.prevent="search2(1,'')" :class="obj2.destination=='' ? 'active' : ''">全部</a>
						</li>
						<li v-for="(item,i) in leftlist2.destination" :key="i">
							<a href="#"   @click.prevent="search2(1,item)"
							:class="obj2.destination==item ? 'active' : ''">· {{item}}</a>
						</li>
					</ul>
				</div>
				<div class="list">
					<h3>热门主题</h3>
					<ul>
						<li>
							<a href="#" @click.prevent="search2(2,'')" :class="obj2.features=='' ? 'active' : ''">全部</a>
						</li>
						<li v-for="(item,i) in leftlist2.features" :key="i">
							<a href="#"  @click.prevent="search2(2,item)"
							:class="obj2.features==item ? 'active' : ''">· {{item}}</a>
						</li>
					</ul>
				</div>
				<div class="list">
					<h3>游玩天数</h3>
					<ul>
						<li>
							<a href="#" @click.prevent="search2(3,'')" :class="obj2.t_days=='' ? 'active' : ''">全部</a>
						</li>
						<li  v-for="i in 3" :key="i">
							<a  href="" @click.prevent="search2(3,(i+3))"
							:class="obj2.t_days==(i+3) ? 'active' : ''">· {{i+3}}天</a>
						</li>
						<li >
							<a  href="" @click.prevent="search2(3,'6d')"
							:class="obj2.t_days=='6d' ? 'active' : ''">· 6天以上</a>
						</li>
					</ul>
				</div>

				<img src="../assets/index/img2.png" alt="上海周边活动" class="img">
			</div>
			<!-- → -->
			<div class="aright">
				<div class="title flex-between">
					<p>
						<span class="active">精选</span>
						<span v-for="(item,i) in leftlist2.provinces" :key="i" @click="search2(4,item)" style="cursor:pointer">
							{{item}}
						</span>
					</p>
					<img src="../assets/index/more.png" alt="" @click="toactivelist('/domesticTravels')">
				</div>
				<div class="activelist">
					<div class="item"  v-for="(item,i) in goodsIndexList2" :key="i" @click="todetails(item.id)">
						<div class="img">
							<img :src="item.goods_pic" alt="" class="image">
							<div class="day">
								<p class="d1"><span>{{item.t_days}}</span> 日</p>
								<p class="d2">行程天数</p>
							</div>
							<div class="adress">{{item.origin_name}}出发</div>
							<div class="join">{{item.porder_count}}人已参加</div>
						</div>
						<div class="text">
							<h3>{{item.goods_name | wuellipsis(40)}}</h3>
							<div class="price-item" v-if="item.price_type === 2">
								<span class="label">预售定金</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<div class="price-item" v-else-if="item.price_type == 1">
								<span class="label">限时特价</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<p v-else><span>¥{{item.price | priceFilter}}</span> 起</p>
						</div>
						<div class="time">
							<div class="date-label"></div>
							<div v-for="(tuan,j) in item.goods_price.slice(0,4)" :key="j" class="tt">
								<p class="item-date">{{tuan.buy_date}}</p>
								<p v-if="tuan.is_team==1" class="item-status orange">已成团</p>
								<p v-else-if="tuan.remain_number === 0 && tuan.remain_alternate_number === 0" class="item-status orange">已满团</p>
								<p class="item-status" v-else>可报名</p>
							</div>

						</div>
					</div>
					<div  class="nothing" v-if="goodsIndexList2.length==0">
						<img src="../assets/nothing.png" alt=""/>
					</div>
				</div>

			</div>
		</div>
		<!-- 广告 -->
		<div class="twoimg flex-between">
			<img :src="item.attachment"  alt="" v-for="(item,i) in recommendList2" :key="i" @click="tourl(item)">
			<!-- <img src="../assets/index/item.jpg" alt="">
			<img src="../assets/index/item3.jpg" alt=""> -->
		</div>
		<div class="line flex-between">
			<div class="y"></div>
			<div class="l"></div>
			<div class="y"></div>
		</div>
		<!-- 境外旅行 -->
		<div class="around_active">
			<!-- ← -->
			<div class="aleft bg3">
				<div class="title">
					<img src="../assets/index/active3.png" alt="">
					<h2>境外旅行</h2>
				</div>
				<div class="list">
					<h3>热门目的地</h3>
					<ul>

						<li>
							<a href="#" @click.prevent="search3(1,'')" :class="obj3.destination=='' ? 'active' : ''">全部</a>
						</li>
						<li v-for="(item,i) in leftlist3.destination" :key="i">
							<a href="#"   @click.prevent="search3(1,item)"
							:class="obj3.destination==item ? 'active' : ''">· {{item}}</a>
						</li>
					</ul>
				</div>
				<div class="list">
					<h3>热门主题</h3>
					<ul>

						<li>
							<a href="#" @click.prevent="search3(2,'')" :class="obj3.features=='' ? 'active' : ''">全部</a>
						</li>
						<li v-for="(item,i) in leftlist3.features" :key="i">
							<a href="#"  @click.prevent="search3(2,item)"
							:class="obj3.features==item ? 'active' : ''">· {{item}}</a>
						</li>
					</ul>
				</div>
				<div class="list">
					<h3>游玩天数</h3>
					<ul>
							<li>
							<a href="#" @click.prevent="search3(3,'')" :class="obj3.t_days=='' ? 'active' : ''">全部</a>
						</li>
						<li  v-for="i in 3" :key="i">
							<a href="#"  @click.prevent="search3(3,i+5)"
							 :class="obj3.t_days==(i+5) ? 'active' : ''">· {{i+5}}天</a>
						</li>
						<li >
							<a href="#"  @click.prevent="search3(3,'8d')"
							 :class="obj3.t_days=='8d' ? 'active' : ''">· 8天以上</a>
						</li>
					</ul>
				</div>

				<img src="../assets/index/img3.png" alt="上海周边活动" class="img">
			</div>
			<!-- → -->
			<div class="aright">
				<div class="title flex-between">
					<p>
						<span class="active">精选</span>
						<span v-for="(item,i) in leftlist3.provinces" :key="i" @click="search3(4,item)" style="cursor:pointer">
							{{item}}
						</span>
					</p>
					<img src="../assets/index/more.png" alt="" @click="toactivelist('/travelAbroad')">
				</div>
				<div class="activelist">
					<div class="item"  v-for="(item,i) in goodsIndexList3" :key="i" @click="todetails(item.id)">
						<div class="img">
							<img :src="item.goods_pic" alt="" class="image">
							<div class="day">
								<p class="d1"><span>{{item.t_days}}</span> 日</p>
								<p class="d2">行程天数</p>
							</div>
							<div class="adress">{{item.origin_name}}出发</div>
							<div class="join">{{item.porder_count}}人已参加</div>
						</div>
						<div class="text">
							<h3>{{item.goods_name | wuellipsis(40)}}</h3>
							<div class="price-item" v-if="item.price_type === 2">
								<span class="label">预售定金</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<div class="price-item" v-else-if="item.price_type == 1">
								<span class="label">限时特价</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<p v-else><span>¥{{item.price | priceFilter}}</span> 起</p>
						</div>
						<div class="time">
							<div class="date-label"></div>
							<div v-for="(tuan,j) in item.goods_price.slice(0,3)" :key="j" class="tt">
								<p class="item-date">{{tuan.buy_date}}</p>
								<p v-if="tuan.is_team==1" class="item-status orange">已成团</p>
								<p v-else-if="tuan.remain_number === 0 && tuan.remain_alternate_number === 0" class="item-status orange">已满团</p>
								<p class="item-status" v-else>可报名</p>
							</div>

						</div>
					</div>
					<div  class="nothing" v-if="goodsIndexList3.length==0">
						<img src="../assets/nothing.png" alt=""/>
					</div>
				</div>

			</div>
		</div>
		<!--picture-->
		<!-- <div class="picture">
			<div class="title flex-between">
				<p>
					<img src="../assets/public/camera.png" alt="">
					<span>游记·旅拍</span>
				</p>
				<img src="../assets/index/more.png" alt="" @click="toactivelist('/trivialShot')">
			</div>
			<div class="pics flex-between">

				<div class="p1">
					<div :class="'p1'+(i+1)" @click="toimginfo(item.id,item.type_id)" v-for="(item,i) in img1" :key="i">
						<img :src="item.attachment" alt="">
						<div class="bg"></div>
						<p>{{item.title}}</p>
					</div>

				</div>
				<div class="p2">
					<div :class="'p2'+(i+1)" @click="toimginfo(item.id,item.type_id)" v-for="(item,i) in img2" :key="i">
						<img :src="item.attachment" alt="">
						<div class="bg"></div>
						<p>{{item.title}}</p>
					</div>

				</div>
				<div class="p3">
					<div :class="'p3'+(i+1)" @click="toimginfo(item.id,item.type_id)" v-for="(item,i) in img3" :key="i">
						<img :src="item.attachment" alt="">
						<div class="bg"></div>
						<p>{{item.title}}</p>
					</div>
				</div>
			</div>
		</div> -->
		<div style="height:50px"></div>
		<div id="searchBar">

		</div>

	</div>

</template>

<script>
import {attributeListAPI,recommendListAPI,goodsIndexListAPI,trvaelArticleAPI} from "@/api/index"
export default {
  // name: 'main',
  components: {

  },
  mounted () { // 给window添加一个滚动滚动监听事件
		  window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () { // 离开该页面需要移除这个监听的事件
		  window.removeEventListener('scroll', this.handleScroll)
  },
  data () {
    return {
		leftlist1:[],
		leftlist2:[],
		leftlist3:[],
		recommendList1:[],
		recommendList2:[],
		offsetTop:0,
		obj1:{
			pid:4,
			provinces:"精选",
			t_days:"",
			destination:"",
			features:""
		},
		obj2:{
			pid:3,
			provinces:"精选",
			t_days:"",
			destination:"",
			features:""
		},
		obj3:{
			pid:2,
			provinces:"精选",
			t_days:"",
			destination:"",
			features:""
		},
		goodsIndexList1:[],
		goodsIndexList2:[],
		goodsIndexList3:[],
		trvaelArticle:[],
		img1:[],
		img2:[],
		img3:[],
    }
  },
  created(){
	  this.getleftlist()
	  this.getrecommendList()
	  this.getgoodsIndexList()
	//   this.gettrvaelArticle()
  },
  methods: {
    handleScroll () { // 改变元素#searchBar的top值
		var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		var offsetTop = document.querySelector('#searchBar').offsetTop
		if (scrollTop <= 570) {
			offsetTop = 570 - Number(scrollTop)
			document.querySelector('#searchBar').style.top = offsetTop + 'px'
		} else {
			document.querySelector('#searchBar').style.top = '10px'
		}
	},
	getleftlist(){
		attributeListAPI({pid:4}).then(res=>{
			this.leftlist1=res.cateView[0]

		})
		attributeListAPI({pid:3}).then(res=>{
			this.leftlist2=res.cateView[0]
		})
		attributeListAPI({pid:2}).then(res=>{
			this.leftlist3=res.cateView[0]
		})
	},
	getrecommendList(){
		recommendListAPI().then(res=>{
			var array=res.cateList
			for (let index = 0; index < array.length; index++) {
				if (index<2) {
					this.recommendList1.push(array[index])
				}else if(index>=2 && index<4){
					this.recommendList2.push(array[index])
				}

			}
			// this.recommendList=res.cateList
		})
	},
	// 活动版块：1=城市微游；2=境外旅行；3=国内长途；4=周边活动；5=趣·户外；6=趣·旅游
	getgoodsIndexList(){
		goodsIndexListAPI(this.obj1).then(res=>{
			this.goodsIndexList1=res.cateList
		})
		goodsIndexListAPI(this.obj2).then(res=>{
			this.goodsIndexList2=res.cateList
		})
		goodsIndexListAPI(this.obj3).then(res=>{
			this.goodsIndexList3=res.cateList
		})

	},
	search1(i,item){
		switch(i){
			case 1:
				this.obj1.destination=item
			break;
			case 2:
				this.obj1.features=item
			break;
			case 3:
				this.obj1.t_days=item
			break;
			case 4:
				this.obj1.provinces=item
			break;
		}
		goodsIndexListAPI(this.obj1).then(res=>{
			this.goodsIndexList1=res.cateList
		})
	},
	search2(i,item){
		switch(i){
			case 1:
				this.obj2.destination=item
			break;
			case 2:
				this.obj2.features=item
			break;
			case 3:
				this.obj2.t_days=item
			break;
			case 4:
				this.obj2.provinces=item
			break;
		}
		goodsIndexListAPI(this.obj2).then(res=>{
			this.goodsIndexList2=res.cateList
		})
	},
	search3(i,item){
		switch(i){
			case 1:
				this.obj3.destination=item
			break;
			case 2:
				this.obj3.features=item
			break;
			case 3:
				this.obj3.t_days=item
			break;
			case 4:
				this.obj3.provinces=item
			break;
		}
		goodsIndexListAPI(this.obj3).then(res=>{
			this.goodsIndexList3=res.cateList
		})
	},
	gettrvaelArticle(){
		trvaelArticleAPI().then(res=>{
			var array=res.cateList
			for (let index = 0; index < array.length; index++) {
				if (index<2) {
					this.img1.push(array[index])
				}else if(index>=2 && index<5){
					this.img2.push(array[index])
				}else if(index>=5 && index<7){
					this.img3.push(array[index])
				}


			}

		})
	},
	todetails(id){
		this.$router.push({ path: '/details',query:{id} })
	},
	toimginfo(id,tid){
		if (tid==1) {
			this.$router.push({ path: "/imginfo", query: {id} });
		} else {
			this.$router.push({ path: "/videoinfo", query: {id} });
		}


	},
	toactivelist(url){
		this.$router.push({ path: url })
	},
	tourl(item){
		switch(item.type_id){
			case 1:
				this.todetails(item.goods_id)
			break;
			case 2:
			   this.$router.push({ path: '/bannerinfo',query:{id:item.id} })
			break;
			case 3:
				this.toimginfo(item.goods_id,item.type_id)
			break;
		}
	}


  }
}
</script>

<style lang="scss" scoped>
	.left{
		position: fixed;
		top:570px;
		left: 5px;
		width: 60px;
		height: 100px;
		 background-color: red;
	}
	.main {
		background: #f9f9f9;
		min-width: 1200px;
	}

	.around_active {
		display: flex;
		width: 1200px;
		margin: 0px auto;
		.bg1{
			background: #fff url('../assets/index/bg1.png') no-repeat;
		}
		.bg2{
			background: #fff url('../assets/index/bg2.png') no-repeat;
		}
		.bg3{
			background: #fff url('../assets/index/bg3.png') no-repeat;
		}
		.aleft {
			position: relative;
			width:340px;
			height: 760px;
			box-sizing: border-box;

			background-size: 100%;
			border-radius: 10px;
			padding: 15px;
			margin-top: 10px;
			.title {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				h2 {
					font-size: 18px;
					color: #6E380C;
				}

				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}

			.list {
				h3 {
					line-height: 30px;
					font-size: 15px;
					color: #333;
					margin-top: 10px;
				}

				ul {
					display: flex;
					flex-wrap: wrap;

					li {
						margin-right: 15px;
						line-height: 25px;

						a {
							font-size: 13px;
							color: #666666;
						}
					}
					.active{
						font-weight: bold;
						color: #ffa300;
					}
				}
			}

			.img {
				position: absolute;
				bottom: 20px;
				left: 0;
				width:100%;
			}
		}

		.aright{
			flex: 1;
			margin-top: 10px;
			// background-color: red;
			margin-left: 20px;
			.title{
				// width: 800px;
				height: 30px;
				span{
					font-size: 13px;
					margin-right: 14px;
					color: #333333;
				}
				.active{
					font-weight: bold;
					color: #ffa300;
					font-size: 17px;
				}
			}
			.activelist{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.item{
					width: 410px;
					background-color: #fff;
					margin-top: 15px;
					border-radius: 5px;
					.img{
						position: relative;

						width: 410px;
						height: 200px;
						overflow: hidden;
						.image{
							width: 100%;
							min-height: 200px;
							border-radius: 5px 5px 0 0;
						}
						.day{
							position: absolute;
							top: -1px;
							left: 0;
							width: 55px;
							background:rgba(0,0,0,0.4) url(../assets/index/day.png) no-repeat;
							background-size: 100%;
							border-radius: 5px 0 0 0 ;
							p{
								color: #fff;
								text-align: center;
								font-size: 12px;
								line-height: 35px;
								span{
									color: #fff;
									font-weight: 500;
									font-size: 17px;
								}
							}
							.d2{
								line-height: 24px;
								font-size: 11px;
							}
						}

						.adress{
							position: absolute;
							bottom: 5px;
							left: 5px;
							padding: 5px 8px;
							font-size: 11px;
							color: #fff;
							background-color: rgba(0,0,0,0.4);
							border-radius:3px;
						}
						.join{
							position: absolute;
							bottom: 5px;
							right: 5px;
							padding: 5px 8px;
							font-size: 11px;
							color: #fff;
							background-color: rgba(0,0,0,0.4);
							border-radius:3px;
						}
					}
					.text{
						padding: 5px 10px;
						border-bottom: 1.5px solid #e6e6e6;
						min-height: 120px;
						// background-color: #666666;
						h3{
							font-size: 17px;
							font-weight: 600;
							line-height: 25px;
						}
						p{
							color: #ff4c12;
							font-size: 12px;
							margin-top: 5px;
							span{
								font-size: 18px;
								font-weight: 600;
								text-align: left;
								color: #ff4c12;
							}
						}

					}

					.time{
						padding: 10px;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						.date-label {
							width: 35px;
							height: 38px;
							background-repeat: no-repeat;
							background-size: 35px 38px;
							background-image: url('../assets/date-label.png');
						}
						// .tt-wrap {
						// 	overflow-x: scroll;
						// }
						.tt{
							padding: 8px;
							margin-left: 8px;
							text-align: center;
							background: #F7F8FA;
							border-radius: 8px 8px 8px 8px;
							p{
								font-size: 13px;
								line-height: 20px;
								color: #666;
								line-height: 20px;

							}
							.orange{
								color:#FF4C12
							}
							.item-date {
								font-weight: bold;
								font-size: 16px;
								color: #1A1A1A;
								line-height: 20px;
								text-align: center;
								white-space: nowrap;
							}
							.item-status {
								font-size: 12px;
								color: #FA4C27;
								line-height: 14px;
								text-align: center;
							}
						}
					}
					.price-item {
						.label {
							font-weight: 500;
							font-size: 12px;
							color: #FA4C27;
							line-height: 17px;
						}
						.val {
							font-weight: bold;
							font-size: 20px;
							color: #FA4C27;
							line-height: 23px;
							margin-left: 6px;
							&::before {
								content: '￥';
								font-size: 10px;
							}
						}
						.val2 {
							font-weight: 500;
							font-size: 12px;
							color: #808080;
							line-height: 15px;
							margin-left: 4px;
						}
					}
				}
				.nothing{
					width: 100%;
					height: 700px;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						width: 500px;
					}
				}
			}
		}
		.active{
			color: #ffa300;
		}
	}

	.twoimg{
		width: 1200px;
		margin: 20px auto;
		img{
			width: 594px;
			height: 100px;
			border-radius: 5px;
		}
	}
	.line{
		width: 1200px;
		margin: 0 auto;
		.y{
			width: 8px;
			height: 8px;
			background: #f9f9f9;
			border: 1px solid #c2c2c2;
			border-radius: 50%;
		}
		.l{
			width: 1196px;
			height: 1px;
			border: 1px dashed rgba(151,151,151,0.55);
		}

	}

	.picture{
		width: 1200px;
		height: 700px;
		margin: 20px auto;
		.title{

			height: 40px;
			p{
				display: flex;
				align-items: center;
				span{
					font-size: 20px;
					font-weight: 600;
					color: #333333;
					line-height: 30px;
					margin-left: 15px;
				}
				img{
					width: 20px;
					height: 20px;
				}
			}

		}

		.pics{
			margin-top: 10px;
			>div >div{
				overflow: hidden;
				position: relative;
				p{
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;

					text-align: center;
					font-size: 20px;
					z-index: 2;
					color: rgba(255,255,255,0);
					transition: color .3s linear;
				}
			}
			.bg{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 0px;
				transition: all .6s linear;
			}
			>div >div:hover .bg{
				// z-index: 5;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.50);
				// transition: height 1s linear;
				cursor: pointer;
			}
			>div >div:hover p{
				color: rgba(255,255,255,1);
			}
			img{
				width: 100%;
				min-height: 100%;
			}
			.p1{
				.p11{
					width: 295px;
					height: 402px;
					img{
						height: 100%;
						width: auto;
					}
				}
				.p12{
					width: 295px;
					height: 196px;
					margin-top: 10px;
				}

			}
			.p2{

				.p21{
					width: 295px;
					height: 196px;
				}
				.p22{
					width: 295px;
					height: 194px;
					margin-top: 10px;
				}
				.p23{
					width: 295px;
					height: 196px;
					margin-top: 10px;
				}
			}
			.p3{
				.p31{
					width: 594px;
					height: 196px;
				}
				.p32{
					width: 594px;
					height: 402px;
					margin-top: 10px;
				}

			}
		}
	}

</style>
